import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
export interface GravatarSignature {
    Element: HTMLImageElement;
    Args: {
        hash: string;
        retina: boolean;
        size: number;
    };
}
export default class Gravatar extends Component<GravatarSignature> {
    get src() {
        const { s: s1 } = this;
        const { hash: hash1 } = this.args;
        return `https://www.gravatar.com/avatar/${hash1}?s=${s1}`;
    }
    get s() {
        const { retina: retina1, size: size1 = 250 } = this.args;
        return retina1 ? size1 * 2 : size1;
    }
    static{
        template(`
    <img
      src={{this.src}}
      class="gravatar"
      width={{@size}}
      height={{@size}}
      alt="avatar"
      ...attributes
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
