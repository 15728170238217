import { template } from "@ember/template-compiler";
import currentYear from '../helpers/current-year';
import type { TOC } from '@ember/component/template-only';
import type { ApplicationRouteModel } from '../routes/application';
export interface FooterSignature {
    Element: HTMLElement;
    Args: {
        city: ApplicationRouteModel['city'];
    };
}
const Footer: TOC<FooterSignature> = template(`
  <footer class="mastfoot">
    <p>{{(currentYear)}}, {{@city}}</p>
  </footer>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Footer;
